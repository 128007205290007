let asciiName = `
                   88 88                                             
                   "" 88                                             
                      88                                             
88,dPYba,,adPYba,  88 88  ,adPPYba, ,adPPYba, ,adPPYYba,  ,adPPYba,  ,adPPYb,d8 
88P'   "88"    "8a 88 88 a8P_____88 I8[    "" ""     'Y8 a8"     ""  a8"    'Y88
88      88      88 88 88 8PP"""""""  '"Y8ba,  ,adPPPPP88 8b          8b       88
88      88      88 88 88 "8b,   ,aa aa    ]8I 88,    ,88 "8a,   ,aa  "8a    ,d88
88      88      88 88 88  '"Ybbd8"' '"YbbdP"' '"8bbdP"Y8  '"Ybbd8"'  '"YbbdP'88 
                                                                             88
                                                                             88`;

let version = <p>version 5.66.9</p>;

let aboutMe = (
  <p>
    miles acquaviva is a soon-to-be graduate of{" "}
    <a href="https://rutgers.edu">Rutgers University</a> studying music
    education and computer science. <br />
    on the tech side, i specialize in software engineering working with{" "}
    <a href="https://www.typescriptlang.org/">TypeScript</a>,{" "}
    <a href="https://developer.apple.com/swift/">Swift</a>,{" "}
    <a href="https://www.oracle.com/java/">Java</a> and{" "}
    <a href="https://en.wikipedia.org/wiki/C_(programming_language)">C</a>{" "}
    <br />
    most often. some of my favorite projects are <br />
    {"  "}
    <a href="https://github.com/link5669/Atonal-Aleatoric-Generator">
      this
    </a>{" "}
    program that generates music in the style of twentieth century
    {"  "}aleatoric composers, <br />
    {"  "}
    <a href="https://github.com/link5669/quizgpt">this</a> web-app that
    {"  "}uses AI to test your knowledge on any topic imaginable,
    <br />
    {"  "}and <a href="https://github.com/link5669/circles">this</a>, an
    {"  "}in-progress social media app that uses NFC to guarantee real-life
    {"  "}connections
  </p>
);

let educationReadMe = (
  <p>
    miles has a degree in music education from Rutgers University and a Teaching
    Certificate of Eligibility with Advanced Standing from the state of New Jersey
    <br />
    my teaching experience includes:
    <ul>
      <li>student teaching in middle and high school classrooms</li>
      <li>creating, administering and grading student assessments</li>
      <li>completing four co-operative placements at various schools across New Jersey</li>
      <li>
        tutoring advanced mathematics courses at the college level
      </li>
    </ul>
  </p>
);

let musicReadMe = (
  <p>
    miles has over 14 years of musical experience as a violinist, pianist,
    composer and arranger <br />
    some highlights of my career include
    <ul>
      <li>
        performing on the soundtrack for Universal Studios' film{" "}
        <i>Sky Blossom</i>
      </li>
      <li>
        selected as a first violinist in orchestras at Berklee College of Music,
        MIT, and Rutgers University
      </li>
      <li>
        performing my original music in a solo violin recital at Mason Gross
        School of the Arts
      </li>
      <li>performing live for a US supreme court justice</li>
    </ul>
  </p>
);

let programmingReadMe = (
  <p>
    miles has work experience as a fullstack software engineer and enjoys
    working with web, desktop and mobile applications
    <br />
    <br />
    languages: Java, Python, C/C++, SQL, JavaScript/TypeScript, HTML/CSS, Swift,
    Kotlin, PHP, Haskell <br />
    frameworks: React, Node.js, Android/iOS Development, Flask, JUnit,
    Material-UI <br />
    developer tools: Git, Docker, Google Cloud, Azure, AWS, VS Code, PyCharm,
    IntelliJ, Eclipse <br />
    projects:
    <br />
    <br />
    <b>Ad Manager</b>
    <ul>
      <li>Created a REST API with Spring Boot Web Flux</li>
      <li>
        Utilizes a custom ML model to place ads in the most relevant stores
      </li>
      <li>
        Used scalable techniques, such as implementing microservices, NoSQL
        database and CDN
      </li>
      <li>Used GitLab CI/CD to ensure fast time to production</li>
    </ul>
    <b>Quizify</b>
    <ul>
      <li>
        Quiz game built using generative AI that allows for an infinite number
        quiz topics and question
      </li>
      <li>Deployed to the internet using Microsoft Azure</li>
    </ul>
    <b>acqOS (in-progress)</b>
    <ul>
      <li>Built from the ground-up with my own code</li>
      <li>Unix-inspired OS to learn how kernels and operating systems work</li>
    </ul>
    <b>Circles (in-progress)</b>
    <ul>
      <li>
        A unique social media app intended to encourage real-life connections
      </li>
      <li>Built with React Native for cross-platform mobile development</li>
    </ul>
    <b>GoNES! (in-progress)</b>
    <ul>
      <li>An NES emulator written in Golang</li>
      <li>
        Fully implemented a cycle-accurate 6502 CPU, including undocumented
        opcodes
      </li>
      <li>
        Reads from machine code or assembly langage and can freely convert
        between the two
      </li>
    </ul>
    <b>Wavyrn</b>
    <ul>
      <li>
        Website built for Wavyrn audio studio, launched at GDC 2024 in San
        Francisco
      </li>
    </ul>
    <b>Zelda Reverse Engineering</b>
    <ul>
      <li>self-taught reverse engineering PowerPC Assembly to C code</li>
      <li>contributed matching code to open source project dedicated to reverse engineering the <i>Legend of Zelda</i> series</li>
    </ul>
  </p>
);

export { asciiName, aboutMe, version, musicReadMe, programmingReadMe, educationReadMe };
