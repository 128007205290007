import "../styles.css";

function CommandInput({
  workingDir,
  output,
  inputChange,
  processChange,
  input,
  prompt
}) {
  return (
    <div class="wrapper">
      <div class="ui">
        {output.map((cmd, index) => {
          return (
            <>
              <pre
                key={index}
                style={{ color: "#8C93A8", fontFamily: "monospace" }}
              >
                {cmd}
              </pre>
            </>
          );
        })}
        <p style={{ display: "inline-block" }}>
          {prompt} {workingDir} %{" "}
        </p>
        <input
          onChange={inputChange}
          onKeyDown={processChange}
          autoComplete="nope"
          ref={input}
          style={{ color: "#8C93A8", fontFamily: "monospace" }}
          autoFocus
        />
      </div>
    </div>
  );
}

export default CommandInput;
