const Navigation = ({ processCmd }) => {
  return (
    <>
      <ul
        style={{
          float: "right",
          listStyle: "none",
          fontFamily: "monospace",
          color: "white",
          paddingRight: "30px",
          fontSize: "2em",
        }}
      >
        <li>NAVIGATION:</li>
        <ul>
          <li onClick={() => processCmd("neofetch")}>about me</li>
          <li
            onClick={() => {
              processCmd("cat /music/README.txt");
            }}
          >
            music
          </li>
          <li
            onClick={() => {
              processCmd("cat /education/README.txt");
            }}
          >
            education
          </li>
          <li
            onClick={() => {
              processCmd("cat /programming/README.txt");
            }}
          >
            programming
          </li>
        </ul>
      </ul>
    </>
  );
};

export default Navigation;
