import { useEffect, useRef, useState } from "react";
import "./styles.css";
import CommandInput from "./components/CommandInput";
import Navigation from "./components/Navigation";
import { useSelector } from "react-redux";
import store from "./store/fsStore";
import {
  aboutMe,
  asciiName,
  version,
  musicReadMe,
  programmingReadMe,
  educationReadMe,
} from "./components/Commands";

function App() {
  const [currCmd, setCurrCmd] = useState("");
  const [output, setOutput] = useState([
    "Last login: " + Date(),
    "type 'help' for more info",
  ]);
  const input = useRef();
  const [workingDir, setWorkingDir] = useState("/");
  const fsRoot = useSelector((state) => state.fsRoot);
  const [currDir, setCurrDir] = useState(fsRoot);
  const prompt = "milesacq@milesacq.com"

  function changeDirectory(newDir) {
    newDir = newDir.split("/");
    let tempCurrDir = currDir;
    let depth = 0;
    let addPath = workingDir;
    let found = false;
    while (depth < newDir.length) {
      if (newDir[depth] == "..") {
        if (addPath == "/" || addPath == "") {
          break
        }
        tempCurrDir = tempCurrDir.parent;
        depth++;
        let addPathSplit = addPath.split("/");
        addPath = addPathSplit.slice(0, addPathSplit.length - 1).join("/");
        if (addPath == "") {
          addPath = "/";
        }
        found = true;
        break;
      }
      for (let i = 0; i < tempCurrDir.children.length; i++) {
        if (tempCurrDir.children[i].name == newDir[depth]) {
          tempCurrDir = tempCurrDir.children[i];
          if (addPath != "/") {
            addPath = addPath + "/" + tempCurrDir.name;
          } else {
            addPath = addPath + tempCurrDir.name;
          }
          depth++;
          found = true;
          break;
        } else if (newDir[depth] == "..") {
          tempCurrDir = tempCurrDir.parent;
          depth++;
          let addPathSplit = addPath.split("/");
          addPath = addPathSplit.slice(0, addPathSplit.length - 1).join("/");
          if (addPath == "") {
            addPath = "/";
          }
          found = true;
          break;
        }
      }
      if (!found) {
        setOutput([...output, prompt, "cd " + newDir.join("/"), "directory not found"]);
      }
    }
    setWorkingDir(addPath);
    setCurrDir(tempCurrDir);
    setOutput([...output, prompt, "cd " + newDir.join("/"), addPath]);
    return true;
  }

  useEffect(() => {
    processCmd("neofetch");
    store.dispatch({
      type: "ADD_DIR",
      payload: { name: "music", location: workingDir },
    });
    store.dispatch({
      type: "ADD_DIR",
      payload: { name: "programming", location: workingDir },
    });
    store.dispatch({
      type: "ADD_DIR",
      payload: { name: "education", location: workingDir },
    });
    store.dispatch({
      type: "ADD_FILE",
      payload: { name: "README.txt", location: "/music" },
    });
    store.dispatch({
      type: "ADD_FILE",
      payload: { name: "README.txt", location: "/programming" },
    });
    store.dispatch({
      type: "ADD_FILE",
      payload: { name: "README.txt", location: "/education" },
    });
  }, []);

  useEffect(() => {
    if (currCmd.includes("\n")) {
      setCurrCmd("");
    }
  });

  function processCmd(cmd) {
    if (cmd == "help") {
      setOutput([
        ...output,
        prompt, 
        cmd,
        "neofetch - fetch information about this user\ncd - change directory (usage 'cd directory_name')\nmkdir - make new directory (usage 'mkdir directory_name')\nls - list contents of a directory\npwd - print working directory\ncat - view contents of a text file (usage 'cat README.txt')",
      ]);
    } else if (cmd == "neofetch") {
      setOutput([...output, (prompt + " " + workingDir + " % " + cmd), asciiName, version, aboutMe]);
    } else if (cmd == "ls") {
      let dirs = [];
      for (let i = 0; i < currDir.children.length; i++) {
        dirs.push(currDir.children[i].name + "\n");
      }
      for (let i = 0; i < currDir.files.length; i++) {
        dirs.push(currDir.files[i] + "\n");
      }
      setOutput([...output, (prompt + " " + workingDir + " % " + cmd), dirs]);
    } else if (cmd == "pwd") {
      setOutput([...output, (prompt + " " + workingDir + " % " + cmd), workingDir]);
    } else if (cmd.includes("cd")) {
      let newDir = cmd.split(" ")[1];
      changeDirectory(newDir);
    } else if (cmd.includes("mkdir")) {
      store.dispatch({
        type: "ADD_DIR",
        payload: { name: cmd.split(" ")[1], location: workingDir },
      });
      setOutput([...output, (prompt + " " + workingDir + " % " + cmd), "Directory created successfully"]);
    } else if (cmd.includes("cat")) {
      if (cmd.includes("/")) {
        if (cmd.split("/")[1] == "music") {
          setOutput([...output, (prompt + " " + workingDir + " % " + cmd), musicReadMe]);
        } else if (cmd.split("/")[1] == "programming") {
          setOutput([...output, (prompt + " " + workingDir + " % " + cmd), programmingReadMe]);
        } else if (cmd.split("/")[1] == "education") {
          setOutput([...output, (prompt + " " + workingDir + " % " + cmd), educationReadMe]);
        }
      }
      if (workingDir == "/music") {
        setOutput([...output, (prompt + " " + workingDir + " % " + cmd), musicReadMe]);
      } else if (workingDir == "/programming") {
        setOutput([...output, (prompt + " " + workingDir + " % " + cmd), programmingReadMe]);
      } else if (workingDir == "/education") {
        setOutput([...output, (prompt + " " + workingDir + " % " + cmd), "error"]);
      }
    } else {
      setOutput([...output, ""]);
    }
  }

  const processChange = (e) => {
    if (e.key == "Enter") {
      processCmd(currCmd);
      setCurrCmd("");
      e.target.value = "";
    }
  };

  const inputChange = (e) => {
    setCurrCmd(e.target.value);
  };

  return (
    <div style={{ display: "flex" }}>
      <div style={{ flex: 1 }}>
        <CommandInput
          workingDir={workingDir}
          output={output}
          inputChange={inputChange}
          processChange={processChange}
          input={input}
          prompt={prompt}
        />
      </div>
      <div style={{ flex: 1 }}>
        <Navigation processCmd={processCmd} />
      </div>
    </div>
  );
}

export default App;
